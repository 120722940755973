// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/utils/revalidate.ts"
);
import.meta.hot.lastModified = "1742586016000";
}
// REMIX HMR END

import {
  FetcherWithComponents,
  useFetcher,
  useLocation,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from '@remix-run/react'
import { useCallback, useEffect, useState } from 'react'
import { useIsSubmitting } from 'remix-validated-form'

import { usePrevious } from '~common/hooks/usePrevious'

export const useRevalidate = () => {
  // We get the navigate function from React Rotuer
  const navigate = useNavigate()
  // And return a function which will navigate to `.` (same URL) and replace it
  return useCallback(() => {
    navigate('.', { replace: true })
  }, [navigate])
}

/**
 *
 * @param formId parameter allows you to use the hook outside a form.
 * @returns void
 */
export const useRevalidateAfterSubmit = (formId: string) => {
  const submitting = useIsSubmitting(formId)
  const prev = usePrevious(submitting)
  const { state, revalidate } = useRevalidator()
  // We get the navigate function from React Rotuer
  // And return a function which will navigate to `.` (same URL) and replace it
  return useEffect(() => {
    if (prev && prev != submitting && state !== 'loading') {
      revalidate()
    }
  }, [prev, submitting, state, revalidate])
}

/**
 *
 * Revalidates on remix location change
 * @returns void
 */
export const useRevalidateOnLocation = () => {
  const { pathname } = useLocation()
  const prev = usePrevious(pathname)
  const { state, revalidate } = useRevalidator()
  // We get the navigate function from React Rotuer
  // And return a function which will navigate to `.` (same URL) and replace it
  return useEffect(() => {
    if (prev != pathname && state !== 'loading') {
      revalidate()
    }
  }, [prev, pathname, state, revalidate])
}

/**
 *
 * Revalidates when key changes
 * @param key parameter to watch for change.
 * @returns void
 */
export const useRevalidateOnRefreshParam = () => {
  const [searchParams] = useSearchParams()
  const refresh = searchParams.get('refresh')

  const prev = usePrevious(refresh)

  const { state, revalidate } = useRevalidator()
  // We get the navigate function from React Rotuer
  // And return a function which will navigate to `.` (same URL) and replace it
  return useEffect(() => {
    if (prev != refresh && state !== 'loading') {
      revalidate()
    }
  }, [prev, refresh, state, revalidate])
}

/**
 *
 * Revalidates when key changes
 * @param fetcherState.
 * @returns void
 */
export const useRevalidateOnFetcherState = (
  fetcher: FetcherWithComponents<any>,
  /** ingoreDataCheck = true only if you know that api is not returning data or redirecting to same page*/
  ingoreDataCheck?: boolean,
) => {
  const { state: fetcherState, data } = fetcher
  const { state, revalidate } = useRevalidator()
  const prev = usePrevious(fetcherState)

  return useEffect(() => {
    if (
      state !== 'loading' &&
      prev === 'loading' &&
      prev !== fetcherState &&
      (ingoreDataCheck || data)
    ) {
      revalidate()
    }
  }, [fetcherState, data, state, revalidate, ingoreDataCheck])
}

/**
 *
 * @param prefix parameter to prefix key.
 * @returns void
 */
export const useRevalidateKey = (prefix: string) => {
  const [key, setKey] = useState(0)
  const { state } = useRevalidator()
  const prev = usePrevious(state)

  // We get the navigate function from React Rotuer
  // And return a function which will navigate to `.` (same URL) and replace it
  useEffect(() => {
    if (prev != state && state !== 'loading') {
      setKey((prev) => ++prev)
    }
  }, [prev, state])
  return `${prefix}-${key}`
}

interface Options {
  enabled?: boolean
  interval?: number
}

export const useRevalidateOnFocus = ({
  enabled = false,
}: Omit<Options, 'interval'>) => {
  const revalidate = useRevalidate()

  useEffect(() => {
    if (!enabled || typeof window === 'undefined') return
    const onFocus = () => {
      revalidate()
    }
    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [revalidate, enabled])

  useEffect(() => {
    if (!enabled || typeof window === 'undefined') return
    const onVisibilityChange = () => {
      revalidate()
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () =>
      window.removeEventListener('visibilitychange', onVisibilityChange)
  }, [revalidate, enabled])
}

export const useRevalidateOnInterval = ({
  enabled = false,
  interval = 1000,
}: Options) => {
  const revalidate = useRevalidate()
  useEffect(() => {
    if (!enabled || typeof window === 'undefined') return
    const intervalId = setInterval(revalidate, interval)
    return () => clearInterval(intervalId)
  }, [revalidate, enabled])
}

export const useRevalidateOnReconnect = ({
  enabled = false,
}: Omit<Options, 'interval'>) => {
  const revalidate = useRevalidate()
  useEffect(() => {
    if (!enabled || typeof window === 'undefined') return
    const onReconnect = () => {
      revalidate()
    }
    window.addEventListener('online', onReconnect)
    return () => window.removeEventListener('online', onReconnect)
  }, [revalidate, enabled])
}

export const processReferer = (referer: string, origin: string) => {
  if (!referer) {
    return '/'
  }
  const subpaths = referer.replace(origin, '').split('/')
  const paths: string[] = ['']
  for (const m of subpaths) {
    const n = m.trim()
    if (n) {
      if (!Number.isNaN(parseInt(n, 10))) {
        break
      } else {
        paths.push(n)
      }
    }
  }
  return paths
    .join('/')
    .replace(/\?refresh=\d+/, '')
    .replace(/\&refresh=\d+/, '')
}
